import React from 'react'
import { NavLink } from 'react-router-dom';
import './FundSummary.css'
import PerformanceChart from '../PerformanceChart/PerformanceChart'

export const FundSummary = props => {
    const { fund, index, currentIndex, fundFocus } = props;
    let xPos = (index - currentIndex) * 25;
    let left = xPos + "vw";
    let right = -xPos + "vw"
    let cardSize = .75;
    let zIndex = 1
    if (xPos === 0) {
        cardSize = 1.25
        zIndex = 2
    }
    let scale = "scale(" + cardSize + ")"

    return (
        <li
        className="fund-summary"
        key={fund.id}
        onClick={ () => props.toggleIndex(index)}
        style={
            {left: left, right: right, transform: scale, zIndex: zIndex}
            }>
            <div className="top">
                <h3>{fund.name}</h3>
                <div className="performance">
                    {/*<h3><span>$</span>{fund.lastPrice}</h3>
                    <p>{fund.YTD}% 12 MTH</p>*/}
                </div>
            </div>
            <div className="middle">
                
                    {/* {fund && <PerformanceChart fund={fund} /> } */}
                    {fund && <img src={fund.image} /> }
                
            </div>
            <div className="bottom">
                <p>{fund.description}</p>
                <NavLink to={"funds/" + fund.slug} className="learn-more"><p>LEARN MORE</p><div /></NavLink>
            </div>
        </li>
    )
}
