import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { firestoreConnect } from 'react-redux-firebase';
import { Footer } from '../Footer/Footer';
import ContactSection from '../Contact/ContactSection';
import './Funds.css';
import { FundSummary } from './FundSummary/FundSummary'

class Funds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numFunds: 0,
            currentIndex: 2,
            // fundFocus: false
        };
    }

    nextFund = () => {
        if (this.state.currentIndex !== this.state.numFunds - 1) {
            this.setState(prevState => ({
                currentIndex: prevState.currentIndex + 1
            }))
        }
    }
    prevFund = () => {
        if (this.state.currentIndex !== 0) {
            this.setState(prevState => ({
                currentIndex: prevState.currentIndex - 1
            }))
        }
    }
    toggleIndex = (index) => {
        this.setState({
            currentIndex: index
        })
    }
    // toggleLearnMore = () => {
    //     this.setState(prevState => ({
    //         fundFocus: !prevState.fundFocus
    //     }))
    // }
    componentDidUpdate() {
        if (this.state.numFunds === 0) {
            if (this.props.funds) {
                this.setState({
                    numFunds: this.props.funds.length
                })
            }
        }
    }
    render() {
        const { auth, profile, funds } = this.props;
        return (
            <div className="component-container">
                <div className="funds">
                    <div className="intro">
                        <div>
                            <h5>OUR FUNDS</h5>
                            <h3>
                                Domiciled in Aus
                            </h3>
                        </div>
                        <div>
                            <div />
                            <p>Sanlam Private Wealth offers a range of Australian domiciled funds managed by carefully selected international fund managers.</p>
                        </div>
                    </div>
                    <div className="funds-scroll">
                        <div className="bg"></div>
                        <ul className="funds-list">
                            { funds && funds.map((fund, index) => {
                                return (
                                    <FundSummary
                                        index={index}
                                        key={index}
                                        fund={fund}
                                        currentIndex={this.state.currentIndex}
                                        toggleIndex={this.toggleIndex}
                                    />
                                )
                            })}
                        </ul>
                        <div className="bottom-nav">
                            <div className="prev">
                                <button onClick={this.prevFund}>
                                    <div />
                                    <div />
                                </button>
                                <p>Previous</p>
                            </div>
                            <div className="current-position">
                                { funds && funds.map((fund, index) => {
                                    let opacity = 0.3
                                    if (index === this.state.currentIndex) {
                                        opacity = 1
                                    }
                                    return (
                                    <div key={fund.id} style={{opacity: opacity}} />
                                    )
                                })}
                            </div>
                            <div className="next">
                                <p>Next</p>
                                <button onClick={this.nextFund}>
                                    <div />
                                    <div />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactSection activeTopic="our funds" />
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        funds: state.firestore.ordered.funds
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'funds', orderBy: ['position'] }
    ])
)(Funds)