import React, { Component } from 'react'
import Backdrop from './Backdrop.png'
import Clouds from './Clouds.png'
import Foreground from './Foreground.png'
import classes from './Cliff.module.css'

class Cliff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            
        };
    }

    parallaxShift = () => {
        this.setState({
          offset: window.pageYOffset
        });
    };

    
    componentDidMount() {
        window.addEventListener('scroll', this.parallaxShift);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.parallaxShift);
    }
    
    render() {
        return (
            <div className={classes.cliff}>
                <div className={classes.cliffParallax}>
                    <div className={classes.clouds} style={{ zIndex: "2", position: "absolute", bottom: (this.state.offset / 35) + "%"}}>
                        <img src={Clouds} alt=""/>
                    </div>
                    <div className={classes.backdrop} style={{ zIndex: "2", position: "absolute", bottom: (this.state.offset / 25 - 50) + "%"}}>
                        <img src={Backdrop} alt=""/>
                    </div>
                    <div className={classes.foreground} style={{ zIndex: "2", position: "absolute", bottom: (this.state.offset / 15 - 90) + "%"}}>
                        <img src={Foreground} alt=""/>
                    </div>
                    {/* <div className={classes.foregroundCover} style={{ zIndex: "2",position: "absolute", bottom: "0", height: (this.state.offset / 25 - 50) + "vw"}} /> */}
                    <div className={classes.bg} />
                    <div className={classes.left} />
                    <div className={classes.right} />
                </div>  
                <div className={classes.cliffText}>

                </div>
            </div>
        )
    }
}

export default Cliff