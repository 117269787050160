const initState = {}

const contactReducer = (state = initState, action) => {
    switch(action.type){
        case 'CONTACT_SUCCESS':
            return {
                ...state,
                contactError: 'New contact'
            }
        case 'CONTACT_ERROR':
            return {
                ...state,
                contactError: 'Contact failed'
            }
        default:
            return state;
        }
    }
    
export default contactReducer;