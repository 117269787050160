import React, { Component } from 'react'
import { connect } from 'react-redux';
import { contact } from '../../store/actions/contactActions';
import * as EmailValidator from 'email-validator';
import { PageComponent } from '../PageComponent/PageComponent'
import { BigButton } from '../PageComponent/BigButton/BigButton'
import './ContactSection.css'

class ContactSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            name: "",
            phone: "",
            topics: ["portfolio services", "our funds", "wealth advisory", "corporate finance", "other query" ],
            activeTopic: this.props.activeTopic,
            successfulContact: false,
            topicsShowing: false
        };
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    submitForm = (e) => {
        e.preventDefault();
        let newContact = {
            email: this.state.email,
            phone: this.state.phone,
            topic: this.state.activeTopic,
            name: this.state.name
        }
        console.log(this.state.email, 'message sent.')
        this.props.contact(newContact);
        
        return this.setState({
            successfulContact: true
        });
    }

    toggleTopics = (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            topicsShowing: !prevState.topicsShowing
        }))
    }
    selectTopic = (e, index) => {
        e.preventDefault();
        this.setState(prevState => ({
            activeTopic: this.state.topics[index],
            topicsShowing: !prevState.topicsShowing
        }))
    }

    render() {
        return (
            <PageComponent align="left" colour="blue" height="550px">
                <section className="contact-section">
                    <form onSubmit={this.submitForm}>
                        <h1>Get in touch</h1>
                        <div className="inputs">
                            <div className="input-field">
                                <p>Hi, my name is </p>
                                <input type="text" id="name" placeholder="name" onChange={this.handleChange}></input>
                            </div>
                            <div className="input-field contact-topics">
                                <p>I'd like to discuss </p>
                                { this.state.topicsShowing ?
                                    <ul>
                                        <li><button onClick={(e) => this.selectTopic(e, 0)}>{this.state.topics[0]}</button></li>
                                        <li><button onClick={(e) => this.selectTopic(e, 1)}>{this.state.topics[1]}</button></li>
                                        <li><button onClick={(e) => this.selectTopic(e, 2)}>{this.state.topics[2]}</button></li>
                                        <li><button onClick={(e) => this.selectTopic(e, 3)}>{this.state.topics[3]}</button></li>
                                        <li><button onClick={(e) => this.selectTopic(e, 4)}>{this.state.topics[4]}</button></li>
                                    </ul>
                                : null }
                                <button onClick={this.toggleTopics} className="topics-toggle">
                                    {this.state.activeTopic}
                                    <svg style={{ transform: this.state.topicsShowing ? "rotate(180deg)" : null }} width="21px" height="14px" viewBox="0 0 21 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <polygon id="Path-79" fill="#FFFFFF" points="0.0996468104 0.866789225 10.4822988 13.2494412 20.8649507 0.866789225" />
                                        </g>
                                    </svg>
                                </button>
                            </div>
                            <div className="input-field">
                                <p>My email is </p>
                                <input type="text" id="email" placeholder="hello@me.com" onChange={this.handleChange}></input>
                            </div>
                            <div className="input-field">
                                <p>My phone number is </p>
                                <input type="text" id="phone" placeholder="0400 000 000" onChange={this.handleChange}></input>
                            </div>
                            <div className="input-field submit">
                                <BigButton click={this.submitForm} button={true} success={this.state.successfulContact} colour="white" text="Submit"/>
                            </div>
                        </div>
                    </form>
                </section>
            </PageComponent>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        contact: (newContact => dispatch(contact(newContact)))
    }
}

export default connect(null, mapDispatchToProps)(ContactSection)