import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Home.css';
import { NavLink } from 'react-router-dom';
import { Section } from 'react-scroll-section'
import ContactSection from '../Contact/ContactSection'
import Global from '../Global/Global'
import wave from './Wave.png';
import sky from './Sky.png'
import water from './Water.png'
import ship from './Ship.png';
import wheel from './Wheel.png';
import { Footer } from '../Footer/Footer';
import { AffiliatedSection } from '../Affiliated/AffiliatedSection';
import CollapsibleList from '../CollapsibleList/CollapsibleList';
import { PageComponent } from '../PageComponent/PageComponent';
import { PageHeader } from '../PageComponent/PageHeader/PageHeader';
import Cliff from './Cliff/Cliff'

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            
        };
    }

    parallaxShift = () => {
        this.setState({
          offset: window.scrollY
        });
    };

    
    componentDidMount() {
        window.addEventListener('scroll', this.parallaxShift);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.parallaxShift);
    }
      

    render() {
        const { auth, profile } = this.props;
        
        const listData = [
            {
                title: "Portfolio Services",
                link: '/portfolio-services',
                body: '<p>General investment advice on:</p> \
                        <ul> \
                            <li><div></div>Australian and international listed shares</li> \
                            <li><div></div>Listed and unlisted funds</li> \
                            <li><div></div>Model portfolio solutions</li> \
                        </ul> \
                        <p>Discretionary asset management through our Managed Discretionary Accounts, wide range of international funds, or individually tailored solutions. Combinations of the above for wholesale clients.</p>'
            },
            {
                title: "Funds Management",
                link: '/funds',
                body: '<p> Sanlam Private Wealth is part of a global asset manager with over $50 billion in client funds under management around the world.\n \
                        In Australia, we focus on bringing you access to quality international managers through well regulated local brands.</p> \
                        <p>Click the link below for more details on these funds and many more opportunities.</p>'
            },
            {
                title: "Wealth Advisory",
                link: '/wealth-advisory',
                body: '<p>Sanlam Wealth Advisory offers tailored, strategic wealth advice to high net worth individuals and their families, professionals and business owners.</p>'
            },
            {
                title: "Corporate Finance",
                link: '/corporate-finance',
                body: '<p>Sanlam Private Wealth has established a trusted reputation for providing experienced advice to listed and emerging companies on the full range of capital market activities.</p>'
            }
        ]

        return (
            <div className="component-container">
                <div className="home">
                    {/* <div className="announcement"><div>The Sanlam Real Assets Fund launched July 1st! Click <NavLink to="/funds/sanlam-real-assets">here</NavLink> to see the latest presentation and fund supplements.</div></div> */}
                    <section className="header">
                        <PageHeader
                            title={"Wind in your sails and"}
                            titleSpan={"a crew you can trust"}
                            body={"We work with high net-worth clients, family offices, financial institutions, and not-for-profit organisations to steer towards their financial goals."}
                            link={"/contact"}
                            linkText={"Contact us"}
                            colour={"white"}
                            height={"550px"}
                        />
                            
                        <div className="content">     
                            
                            <div className="sky" style={{ position: "absolute", bottom: (this.state.offset / 60 + 25) + "vw"}}>
                                <img src={sky} alt=""/>
                            </div>
                            <div className="water" style={{ zIndex: "2", position: "absolute", bottom: (this.state.offset / 40) + "vw"}}>
                                <img src={water} alt=""/>
                            </div>
                            <div className="ship" style={{ zIndex: "2", position: "absolute", bottom: (this.state.offset / 40 + 10) + "vw"}}>
                                <img src={ship} alt=""/>
                            </div>
                            <div className="wave" style={{ zIndex: "2", position: "absolute", bottom: (this.state.offset / 25) + "vw"}}>
                                <img src={wave} alt=""/>
                            </div>
                            <div className="blue-spacer" style={{ zIndex: "2",position: "absolute", bottom: (this.state.offset / 25 - 60) + "vw"}}>
                            </div>
                        </div>
                    </section>
                    <section className="list-component services reversed">
                        <div className="content">
                            <div className="divider">
                                <CollapsibleList data={listData} />
                            </div>
                            <div className="divider">
                                <div className="services-summary">
                                    <h5>OUR SERVICES</h5>
                                    <h1>Where could we<br /><span>go together?</span></h1>
                                    <p>
                                        Sanlam helps people, families, businesses and charities to organise and grow their wealth to achieve the things they want in life and protect themselves against the things they don't.
                                        <br />
                                        We have a comprehensive list of investment services and product options, and the capability and experience to help you build your wealth through our focused investment advice.
                                    </p>
                                    
                                </div>
                                <div className="services-illustration">
                                    
                                </div>
                            </div>
                        </div>
                        <div className="spinning-wheel">
                            <img src={wheel} style={{ transform: `rotate(${(this.state.offset / 15) + "deg"})`}} />
                        </div>
                    </section>
                    {/* <Cliff /> */}
                    <AffiliatedSection />
                    <ContactSection activeTopic="portfolio services" />
                    
                    <Global />
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Home);


