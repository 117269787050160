import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import { NavLink } from 'react-router-dom';
import './CollapsibleList.css';

class CollapsibleList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openItem: 0,
            selectedItem: null,
            timerOn: true,
            width: null,
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }
    selectItem = (index) => {
        this.setState({
            selectedItem: index,
            openItem: index
        });
        clearInterval(this.interval)
    };

    componentDidMount() {
        this.interval = setInterval(() => this.nextIndex(), 10000);
        const width = this.divElement.clientWidth;
        this.setState({ width });
        window.addEventListener("resize", this.updateDimensions);
    }
    updateDimensions() {
        const width = this.divElement.clientWidth;
        this.setState({ width });
    }
    componentWillUnmount() {
        clearInterval(this.interval)
        window.removeEventListener("resize", this.updateDimensions);
    }

    nextIndex() {
        const currentIndex = this.state.openItem
        const numItems = this.props.data.length
        let newIndex = currentIndex
        if ((currentIndex + 1) < numItems) this.setState({ openItem: currentIndex + 1 })
        else this.setState({ openItem: 0 })
    }
    getListItemClass(item, index) {
        let itemClass = ""
        if (this.state.selectedItem === index) {
            itemClass = `${item.class} selected noInterval`
        } else if (this.state.openItem === index) {
            itemClass = `${item.class} selected`
        } else {
            if (this.state.width < 450 && ["Focus on desired outcomes", "Build long term relationships"].includes(item.title)) {
                itemClass = `${item.class} wrap`
            }
            if (this.state.width < 370 && ["Listen and understand", "Focus on desired outcomes", "Build long term relationships"].includes(item.title)) {
                itemClass = `${item.class} wrap`
            }
            if (this.state.width < 320 && !["Wealth Advisory", "Portfolio Services"].includes(item.title)) {
                itemClass = `${item.class} wrap`
            }
            if (this.state.width < 300 && item.title !== "Wealth Advisory") {
                itemClass = `${item.class} wrap`
            }
            if (this.state.width < 280) {
                itemClass = `${item.class} wrap`
            }
        }
        return itemClass
    }
    render() {
        
        return (
                <div className="collapsible-list">
                    <ul>
                        {this.props.data && this.props.data.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    // className={this.state.selectedItem === index ? `${item.class} selected noInterval` : this.state.openItem === index ? `${item.class} selected` : ""}
                                    className={this.getListItemClass(item, index)}    
                                >
                                    <div className="selection-timer"><div /></div>
                                    <button
                                    ref={ (divElement) => { this.divElement = divElement } }
                                    className="headline-btn" onClick={() => this.selectItem(index)}>
                                        <h3 className="index">{"0" + (index+1)}</h3><h3 className="headline">{item.title}</h3>
                                    </button>

                                    <div className="list-item-info">
                                        {renderHTML(item.body)}
                                    </div>
                                    { item.link ? <NavLink to={item.link}><button className="learn-more"><p>Learn more</p><div /></button></NavLink> : null }
                                </li>
                            )
                        })}
                    </ul>
                </div>
        )
    }
}

export default CollapsibleList
