import React from 'react'
import './AffiliatedSection.css'
import { NavLink } from 'react-router-dom';
import stake from './BannerLogos/stakeBanner.png'
import stockspot from './BannerLogos/stockspotBanner.png'
import simplyWallSt from './BannerLogos/SimplyWallSt.png'
import maqro from './BannerLogos/maqroBanner.png'
import medallion from './BannerLogos/medallionBanner.png'
import { PageComponent } from '../PageComponent/PageComponent';

export const AffiliatedSection = props => {
    return (
        <PageComponent colour="light">
            <div className="affiliated-section">
                <h3>Partnered with a new generation of financial creators</h3>
                <div className="title-underline"></div>
                <ul>
                    <li><a href="https://simplywall.st/" target="_blank" rel="noopener noreferrer"><img src={simplyWallSt} alt="Stockspot" /></a></li>
                    {/* <li><a href="https://www.stockspot.com.au" target="_blank" rel="noopener noreferrer"><img src={stockspot} alt="Stockspot" /></a></li> */}
                    <li><a href="https://www.hellostake.com" target="_blank" rel="noopener noreferrer"><img src={stake} alt="Stake" /></a></li>
                    {/* <li><a href="https://maqro.com.au/" target="_blank" rel="noopener noreferrer"><img src={maqro} alt="Maqro" /></a></li> */}
                    <li><a href="https://www.medallionfinancial.com.au/" target="_blank" rel="noopener noreferrer"><img src={medallion} alt="Medallion" /></a></li>
                </ul>
                <NavLink to="affiliated" className="meet-our-affiliates"><p>MEET OUR AFFILIATES</p><div /></NavLink>
            </div>
        </PageComponent>
    )
}

