import React, { Component } from 'react'
import globalMap from './GlobalMap.svg'
import './Global.css'

class Global extends Component {
    render() {
        return (
            <section className="global">
                <div>
                    <h5>Global</h5>
                    <ul>
                        <li><a href="http://www.sanlamgis.com/">Global Investment Solutions</a></li>
                        <li><a href="http://www.sanlam.com/">Investor Relations</a></li>
                    </ul>
                </div>
                <div>
                    <h5>Australia</h5>
                    <ul>
                        <li><a href="">Sydney</a></li>
                        <li><a href="">Melbourne</a></li>
                        <li><a href="">Perth</a></li>
                    </ul>
                </div>
                <div>
                    <h5>Europe</h5>
                    <ul>
                        <li><a href="https://www.sanlam.co.uk/">Sanlam UK</a></li> 
                        <li><a href="http://www.spi.sanlam.co.uk/">Sanlam Private Wealth UK</a></li>
                        <li><a href="http://www.sanlamsecuritiesuk.com/">Sanlam Securities UK</a></li>
                        <li><a href="https://www.sanlamfour.com/">Sanlam FOUR</a></li>
                        <li><a href="http://www.sanlam.com/ireland">Sanlam Asset Management Ireland</a></li>
                    </ul>
                </div>
                <div>
                    <h5>Africa</h5>
                    <ul>
                        <li><a href="http://www.sanlam.co.za/">Sanlam South Africa</a></li>
                        <li><a href="http://www.sanlam.com/namibia">Sanlam Namibia</a></li>
                        <li><a href="http://www.sanlam.com/mozambique">Sanlam Mozambique</a></li>
                        <li><a href="http://www.sanlam.com/tanzania">Sanlam Tanzania</a></li>
                        <li><a href="http://www.sanlam.com/uganda">Sanlam Uganda</a></li>
                        <li><a href="http://www.sanlam.com/swaziland">Sanlam Swaziland</a></li>
                        <li><a href="http://www.sanlam.com/kenya">Sanlam Kenya</a></li>
                        <li><a href="http://www.sanlam.com/zambia">Sanlam Zambia</a></li>
                        <li><a href="http://sanlamprivatewealth.mu/">Sanlam Mauritius</a></li>
                    </ul>
                </div>
                <img className="map" src={globalMap} alt="Sanlam around the world" />
            </section>
        )
    }
}

export default Global
