import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

var config = {
    apiKey: "AIzaSyBhkmoWkoEZqFjtXIb5akr_dDRQUyJ3YKs",
    authDomain: "sanlam-au.firebaseapp.com",
    databaseURL: "https://tickertake-gcloud.firebaseio.com",
    projectId: "sanlam-au",
    storageBucket: "sanlam-au.appspot.com",
    messagingSenderId: "71076139267"
};

firebase.initializeApp(config)
firebase.firestore()

export default firebase;