import React, { Component } from 'react'
import ContactSection from '../Contact/ContactSection'
import CollapsibleList from '../CollapsibleList/CollapsibleList'
import MelbournePortfolioServices from '../Team/MelbournePortfolioServices'
import SydneyPortfolioServices from '../Team/SydneyPortfolioServices'
import Bull from './Bull.png'
import { Footer } from '../Footer/Footer'
import './CorporateFinance.css'
import './PortfolioServices.css'
import { CenteredList } from '../CenteredList/CenteredList'

class CorporateFinance extends Component {
    render() {
        const listData = [
            {
                title: "Listen and understand",
                body: '<p>General investment advice on:</p> \
                        <p>Discretionary asset management through our Managed Discretionary Accounts, wide range of international funds, or individually tailored solutions. Combinations of the above for wholesale clients.</p><br />'
            },
            {
                title: "Focus on desired outcomes",
                body: '<p>We have more than just a deep understanding of equities, bonds, property, managed funds, alternative assets and superannuation.</p> \
                        <p>What really sets us apart is the way we consider all options and apply these to every stage of wealth creation.</p><br />'
            },
            {
                title: "Build long term relationships",
                body: '<p>Expertise and understanding are vital.</p> \
                        <p>But it\'s our ability to establish a long term partnership with you that really makes the difference.</p><br />'
            },
        ]

        const centeredListData = [
            {
                title: "Discretionary portfolio management",
                body: 'By delegating the management of part or all of your day-to-day portfolio investment decisions  to a dedicated porftolio manager, we can provide you with access to a top-tier product shelf across asset classes, markets and currencies.'
            },
            {
                title: "Equities",
                body: 'We offer access to domestic equities listed on the ASX and international equities listed on most international exchanges. We offer access to a wide variety of of Australian-registered funds in our product list - not just our own.'
            },
            {
                title: "Real estate",
                body: 'We can provide access to a range of portfolio management options in this sector. Our offering includes access to the listed real estate market both locally and internationally, as well as opportunities in the unlisted space.'
            },
        ]

        return (
            <div className="component-container">
                <div className="portfolio-services">
                    <section className="header">
                        <div className="content">
                            <img src={Bull} alt="Sanlam corporate finance" />
                            <div className="text">
                                <h5>CORPORATE FINANCE</h5>
                                <h1>Expert advice and <br /><span>and management</span></h1>
                                <p>
                                    Sanlam Private Wealth has established a trusted reputation for providing experienced advice to listed and emerging companies on the full range of capital market activities.
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="list-component services">
                        <div className="content fs">
                            <div className="divider">
                                <div className="services-summary">
                                    <h5>OUR SERVICES</h5>
                                    <h1>Extensive distribution<span><br/>for niche opportunities</span></h1>
                                    <p>
                                        Sanlam has extensive international and domestic institutional, family office and high net worth client distribution networks.
                                        <br />
                                        Sanlam focuses on emerging, nano cap and micro-cap companies that are often over looked and excluded by other industry investment houses which enables Sanlam to offer its clients niche investment opportunities.
                                    </p>
                                </div>
                            </div>
                            <div className="divider">
                                <div className="finance-services">
                                    <div>Equity capital market transactions</div>
                                    <div>Equity placements &amp; entitlement offers</div>
                                    <div>Initial public offerings (IPOs)</div>
                                    <div>Private equity &amp; pre-IPO capital raisings</div>
                                    <div>Corporate finance advice and structuring</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <CenteredList data={centeredListData} title={"<h1>We accomodate<br /><span>any strategy</span></h1>"} /> */}
                    {/* <SydneyPortfolioServices />
                    <MelbournePortfolioServices /> */}
                    <ContactSection activeTopic="corporate finance" />
                    <Footer />
                </div>
            </div>
        )
    }
}

export default CorporateFinance
