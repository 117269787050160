export const HOME = '/'
export const ABOUT = '/about'
export const FUNDS = '/funds'
export const AFFILIATED = '/affiliated'
export const CONTACT = '/contact'
export const FUNDMANAGEMENT = '/fund-management'
export const PORTFOLIOSERVICES = '/portfolio-services'
export const WEALTHADVISORY = '/wealth-advisory'
export const CORPORATEFINANCE = '/corporate-finance'
export const PRIVACYPOLICY = '/privacy-policy'
export const FSG = '/financial-services-guide'
export const TANDC = '/terms-conditions'
export const LOGIN = '/login'
export const SIGNUP = '/signup'
export const RECOVERPWD = '/recover'
export const FUND = '/funds/:slug'
export const REALASSETSFUND = '/funds/sanlam-real-assets'
export const MISSINGPAGE = '/404'