const initState = {}

const authReducer = (state = initState, action) => {
    switch(action.type){
        case 'SIGNIN_ERROR':
            return {
                ...state,
                authError: 'Sign in failed'
            }
        case 'SIGNIN_SUCCESS':
            console.log('Sign in success.');
            return {
                ...state,
                authError: null
            }
        case 'SIGNOUT_ERROR':
            return {
                ...state,
                authError: 'Sign out failed'
            }
        case 'SIGNOUT_SUCCESS':
            console.log('Sign out success.');
            return state;
        case 'SIGNUP_ERROR':
            console.log('Signup error.');
            return {
                ...state,
                authError: action.err.message
            }
        case 'SIGNUP_SUCCESS':
            console.log('Sign up success.');
            return {
                ...state,
                authError: null
            }
        default:
            return state;
        }
    }
    
export default authReducer;