import React, { Component } from 'react'
import { PageComponent } from '../PageComponent'
import { BigButton } from '../BigButton/BigButton'
import './PageHeader.css'

export const PageHeader = props => {
    return (
        <PageComponent align="left" height={props.height ? props.height : "700px"}>
            <div className={"page-header " + props.colour}>
                { props.title ?<h1>{props.title}{props.titleSpan ? <span><br/>{props.titleSpan}</span> : null}</h1> : null }
                { props.body ? <p>{props.body}</p> : null }
                { props.link ? <BigButton colour={props.colour} link={props.link} text={props.linkText}/> : null }
            </div>
        </PageComponent>
    )
}
