import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './BigButton.css'

export const BigButton = props => {
    if (props.external) {
        return (
            <a href={props.link ? props.link : ""} target="_blank" rel="noopener noreferrer">
                <button className={"big-button " + props.colour}>
                    <p>{props.text}</p>
                    <div />
                </button>
            </a>
        )
    }
    if (props.button) {
        if (props.success) {
            return (
                <button className={"big-button " + "green"}>
                    <p>Submitted</p>
                    <svg width="44px" height="36px" viewBox="0 0 44 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                        <g transform="translate(-406.000000, -748.000000)" stroke="#FFFFFF" strokeWidth={7}>
                            <polyline id="Path-46" points="410.304713 770.856986 420.458842 780.239807 445.904497 752.110887" />
                        </g>
                        </g>
                    </svg>
                    <div />
                </button>
            )
        } else {
            return (
                <button className={"big-button " + props.colour} onClick={props.click}>
                    <p>{props.text}</p>
                    <div />
                </button>
            )
        }
    }
    else {
        return (
            <NavLink to={props.link ? props.link : ""}>
                <button className={"big-button " + props.colour}>
                    <p>{props.text}</p>
                    <div />
                </button>
            </NavLink>
        )
    }
}
