import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Footer } from '../Footer/Footer';
import YouTube from 'react-youtube';
import ContactSection from '../Contact/ContactSection';
import './Affiliated.css';
import { PageHeader } from '../PageComponent/PageHeader/PageHeader';
import stakeLogo from './Logos/stake.png'
import stockspotLogo from './Logos/stockspot.png'
import stockspotProduct from './ProductImages/stockspot.png'
import fortemLogo from './Logos/fortem.png'
import maqroLogo from './Logos/maqro.png'
import maqroProduct1 from './ProductImages/Maqro1.png'
import medallionLogo from './Logos/medallion.png'
import simplyWallStLogo from './Logos/SimplyWallStLogo.png'
import binoculars from './Binoculars.png'

class Affiliated extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }

    render() {
        const { auth, profile } = this.props;
        const opts = {
            height: '400',
            width: '500',
        }

        return (
            <div className="component-container">
                <div className="affiliated">
                    <section className="header">
                        <PageHeader
                            title={"We keep our eyes"}
                            titleSpan={"on the horizon"}
                            body={" To create real value that lasts for generations to come, we must respect tradition without revering it. We must adapt when saving and investment are inevitably reinterpreted in new, unique ways. With this in mind, we have partnered with a new generation of financial creators, joining their clients on a journey into the future."}
                            colour={"blue"}
                        />
                        <img src={binoculars} alt="Sanlam affiliates" />
                    </section>
                    <section className="affiliate right space-mobile">
                        <div className="content">
                            <div className="divider">
                                <img className="affiliate-logo" src={simplyWallStLogo} alt="Simply Wall St" />
                                <p>
                                    Simply Wall St helps you make confident investment decisions by providing unique, exhaustive analysis on every listed stock worldwide at your fingertips.
                                </p>
                                <a className="cta leftmost" href="https://simplywall.st/"><p>Visit site</p><div /></a>
                                <a className="cta unpad" href="https://apps.apple.com/au/app/simply-wall-st/id1075614972"><p>iOS</p><div /></a>
                                <a className="cta unpad rightmost" href="https://play.google.com/store/apps/details?id=com.simplywallst.app"><p>Android</p><div /></a>
                            </div>

                            <div className="divider">
                                {/* <img className="product-image" src={stockspotProduct} alt="Stockspot" /> */}
                                <YouTube
                                    videoId="nx6hso9B-z0"
                                    opts={opts}
                                    onReady={this._onReady}
                                />
                            </div>
                        </div>
                    </section>
                    {/* <section className="affiliate left space-mobile" style={{backgroundColor: "#0d122c", color: "#FFF"}}>
                        <div className="content">
                            <div className="divider">
                                <img className="affiliate-logo" src={stockspotLogo} alt="Stockspot" />
                                <p>
                                    Stockspot is Australia’s leading online investment adviser. We build you a smart, custom portfolio using ETFs (exchange traded funds), which give you access to a range of different investments with low fees.
                                </p>
                                <a className="cta white leftmost" href="https://www.stockspot.com.au"><p>Visit site</p><div /></a>
                                <a className="cta white unpad" href="https://itunes.apple.com/au/app/stockspot/id1304830353"><p>iOS</p><div /></a>
                                <a className="cta white unpad rightmost" href="https://play.google.com/store/apps/details?id=com.stockspot"><p>Android</p><div /></a>
                            </div>

                            <div className="divider">
                               
                                <YouTube
                                    videoId="g7hu2AILlbM"
                                    opts={opts}
                                    onReady={this._onReady}
                                />
                            </div>
                        </div>
                    </section> */}
                    <section className="affiliate right space-mobile stake">
                        <div className="content">
                            <div className="divider">
                                <YouTube
                                    videoId="fCMOpuQRfxk"
                                    opts={opts}
                                    onReady={this._onReady}
                                />
                            </div>
                            <div className="divider">
                                <img className="affiliate-logo" src={stakeLogo} alt="Stake" />
                                <p>
                                    Stake offers real-time access to trade the US stock market for Australians and New Zealanders.
                                    Own a piece of Apple, Google, Tesla, Baozun, Amazon and thousands more.
                                </p>
                                <a className="cta leftmost" href="https://www.stake.com.au/"><p>Visit site</p><div /></a>
                                <a className="cta unpad" href="https://apps.apple.com/au/app/stake-trade-us-stocks/id1399461540"><p>iOS</p><div /></a>
                                <a className="cta unpad rightmost" href="https://play.google.com/store/apps/details?id=com.stake.stake"><p>Android</p><div /></a>
                            </div>
                        </div>
                    </section>
                    <section className="affiliate left" style={{backgroundColor: "#0d122c", color: "#FFF"}}>
                        <div className="content">
                            <div className="divider">
                                <img className="affiliate-logo" src={maqroLogo} alt="Maqro Wealth" />
                                <p>
                                    Maqro Capital is a stockmarket research and advisory firm, offering convenient and technology-focused products for the everyday Australian investor. Maqro is determined to eliminate the barriers to entry and finally bridge the gap between the retail and institutional investor.
                                </p>
                                <a className="cta white leftmost" href="https://maqro.com.au/"><p>Visit site</p><div /></a>
                            </div>
                            <div className="divider">
                                <img className="product-image" style={{width: "60%"}} src={maqroProduct1} alt="Maqro" />
                                {/* <YouTube
                                    videoId="g7hu2AILlbM"
                                    opts={opts}
                                    onReady={this._onReady}
                                /> */}
                            </div>
                            
                        </div>
                    </section>
                    {/* <section className="affiliate right" style={{backgroundColor: "#FFF"}}>
                        <div className="content">
                            <div className="divider">
                            </div>
                            <div className="divider">
                                <img className="affiliate-logo" src={fortemLogo} alt="Fortem Wealth" />
                                <p>
                                    Fortem Wealth is a privately owned Australian wealth management company with a focus on providing a holistic service for clients wanting to manage and generate wealth efficiently and effectively.
                                    The experience the team provides is cutting edge, staying well ahead of the curve in decision-making for client portfolios in all market conditions.
                                </p>
                                <a className="cta rightmost" href="https://www.fortemwealth.com.au/"><p>Visit site</p><div /></a>
                            </div>
                            
                        </div>
                    </section>
                    <section className="affiliate left" style={{backgroundColor: "#0d122c", color: "#FFF"}}>
                        <div className="content">
                            <div className="divider">
                                <img className="affiliate-logo" src={medallionLogo} alt="Medallion" />
                                
                                <p>
                                Medallion Financial provides a range of private wealth advisory services, with a particular focus on the equity markets.
                                Their clients range from individuals, companies, families, trusts, SMSF’s as well as charitable organisations and foundations.
                                </p>
                                
                                <a className="cta white leftmost" href=""><p>Visit site</p><div /></a>
                            </div>
                            <div className="divider">
                                
                            </div>
                            
                        </div>
                    </section> */}
                </div>
                <ContactSection activeTopic="our affiliates" />
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Affiliated);


