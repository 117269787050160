import React, { Component } from 'react';
import { PageComponent } from '../PageComponent/PageComponent';
import './PrivacyPolicy.css';
import { Footer } from '../Footer/Footer';

export class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="component-container">
                <PageComponent colour={"dark-blue"}>
                <div className="privacy-policy">
                    <h3>Privacy Policy</h3>
                    As an Australian Financial Services licensee, Sanlam Private Wealth is subject to legislative and other regulatory requirements which necessitate the obtaining and holding of information which personally identifies those persons with whom it transacts business. Sanlam Private Wealth is bound by the Australian Privacy Principles established under the Privacy Act 1988.

                    <h5>Collection of Personal Information</h5>
                    Personal information is information or opinion about an individual whose identity is apparent, or can reasonably be determined, from the information or opinion.

                    Sanlam Private Wealth is obliged to collect information about its clients and persons associated with some clients to satisfy regulatory requirements aimed at mitigating money laundering. Sanlam Private Wealth only provides general advice and therefore any information collected in relation to a client’s investment objectives, financial situation and personal needs is not taken into consideration when providing advice
                    Where a client does not provide personal information which is sought, Sanlam Private Wealth may be unable to open (or in some circumstances, to continue to maintain) an account for a client or provide all its services to a client, and may prevent Sanlam Private Wealth from complying with all its legal or regulatory obligations. It may also result in any investment advice being made without reference to the client’s investment objectives, financial situation and particular needs.
                    Guidelines issued by the Privacy Commissioner state that a person has the right to choose not to quote a tax file number and that a tax file number may not be used or disclosed to establish or confirm the identity of a person for any purpose not authorised by taxation, assistance agency or superannuation law. As a securities dealer, Sanlam Private Wealth may request a client to provide it’s tax file number with a view to disclose that tax file number information to investment bodies (including public companies) when making investments on behalf of that client. While not providing a tax file number may lead to amounts being withheld for taxation, it is a client’s right not to quote their tax file number.
                    Sanlam Private Wealth only collects personal information about a client when the client has knowingly provided that information to Sanlam Private Wealth or has authorised a third party to provide that information to it or when Sanlam Private Wealth or a third party service provider acting on its behalf has made reference to either a credit agency as to a client’s credit worthiness or another agency for the purposes of confirming aspects of the client’s identity for anti-money laundering purposes.
                    Sanlam Private Wealth may also collect personal information about officers and staff of our corporate clients; as well as contact people in regulatory bodies, external service providers and the investment industry.

                    <h5>Use and Disclosure of Personal Information</h5>
                    Client information collected by Sanlam Private Wealth enables Sanlam Private Wealth to:
                    <ul>
                        <li>assess the client’s application to open an account, including assessing the client’s credit worthiness;</li>
                        <li>effect purchases and sales of securities and other transactions on behalf of the client and provide related facilities and services (including settlement and sponsorship services); and</li>
                        <li>facilitate compliance with the terms and conditions of agreements with the client for trading on a securities market and with all applicable legal or regulatory requirements.</li>
                    </ul>
                    <h5>Sanlam Private Wealth will not use or disclose any personal information collected by it for any purpose other than</h5>
                    <ul>
                        <li>the purposes for which it was provided or secondary related purposes in circumstances where the client would reasonably expect such use or disclosure;</li>
                        <li>where the client has consented to such disclosure; or</li>
                        <li>where the Australian Privacy Principles authorise use or disclosure where required or authorised under law in circumstances relating to public health and safety or in connection with certain operations by or on behalf of an enforcement body.</li>
                    </ul>
                    <h5>Uses and disclosures of personal information collected by Sanlam Private Wealth include, but are not limited to, the following</h5>
                    The use or disclosure of personal information as required by the Corporations Act or any other applicable law;
                    <ul>
                        <li>the disclosure of personal information to issuers of financial products;</li>
                        <li>the disclosure of a client’s tax file number (if supplied) to companies in which investments are made on that client’s behalf;</li>
                        <li>the disclosure of such information is required or permitted by law; or</li>
                        <li>the use of personal information within Sanlam Private Wealth or its associated entities to establish future strategies and for the development of products or services and for the marketing of those products and services.</li>
                    </ul>
                    In order for third party providers of services (such as execution, clearing and settlement services; margin lending services and any bank which operates a cash management account in the client’s name) to effect or fund dealings in securities on behalf client’s of Sanlam Private Wealth, the disclosure and use of client personal information may be required. Sanlam Private Wealth will only provide access to such information where it is reasonably satisfied that such third party service providers have adequate arrangements in place relating to the use and disclosure of client personal information and as authorised by the client.

                    Sanlam Private Wealth is also obliged by the Corporations Act to maintain certain transaction records and make those records available for inspection by the Australian Securities and Investments Commission.

                    Sanlam Private Wealth may use the personal information collected from a client for the purpose of providing the client with direct marketing material such as product offerings and reports that may be of interest to the client (the client may request not to receive such information and Sanlam Private Wealth will give effect to that request).

                    <h5>Storage and Security</h5>
                    Sanlam Private Wealth will at all times, seek to ensure that the personal information collected and held by it is protected from misuse, loss, unauthorised access, modification or disclosure. At all times a client’s personal information will be treated as confidential. A client’s personal information is generally held in the client’s file. Information may also be held in one or more computer databases.

                    In the event a client ceases to be a client of Sanlam Private Wealth, any personal information which is held about the client will be retained for a period of 7 years in order to comply with legislative requirements; following that time, the information will be destroyed.

                    <h5>Access to Information</h5>
                    A client may contact Sanlam Private Wealth and request access to their personal information and Sanlam Private Wealth will (subject to the following exceptions) provide the client with access to that information either by providing copies of the information requested, allowing the client to inspect the information requested or providing the client with an accurate summary of the information held. Prior to providing such access, Sanlam Private Wealth will require the client to provide evidence of identity.

                    Sanlam Private Wealth will not provide the client with access to the client’s personal information if providing access would have an unreasonable impact on the privacy of others, the request for access is frivolous or vexatious, the information relates to existing or anticipated legal proceedings between Sanlam Private Wealth and the client and would not be discoverable in those proceedings, denying access is required or authorised by or under law, providing access would be unlawful, providing
                    access would be likely to prejudice operations by or on behalf of an enforcement body, and/or an enforcement body has requested that access not be provided on the grounds of national security.

                    In the event that a client’s request for access is refused Sanlam Private Wealth will provide the client with an explanation for that refusal.

                    <h5>Correction of Information</h5>
                    Sanlam Private Wealth will endeavour to ensure that client personal information held by it is up to date and accurate. In the event that a client becomes aware, or believes, that any personal information which Sanlam Private Wealth holds about the client is inaccurate, incomplete or outdated, the client should contact Sanlam Private Wealth, through the client’s adviser, and provide relevant accurate, complete and up to date personal information. Sanlam Private Wealth will, if it agrees that the information requires correcting, take all reasonable steps to correct the information.

                    Sanlam Private Wealth will endeavour to respond to any request for access within 14 to 44 days depending on the complexity of the information and/or the request. If the request is urgent the client should indicate this clearly.

                    <h5>Privacy Complaint</h5>
                    If a client has a complaint about the way in which Sanlam Private Wealth has handled the privacy of that client’s personal information that client should, in the first instance, contact their Sanlam Private Wealth adviser. The Privacy Officer will then investigate the complaint and will generally try to resolve the complaint within 10 business days. If a client is unhappy with the response of Sanlam Private Wealth Privacy Officer, the client is entitled to contact the Office of the Australian Information Commissioner, which may investigate the client’s complaint further.
                </div>
                </PageComponent>
                <Footer />
            </div>
        )
    }
}

export default PrivacyPolicy
