import React, { Component } from 'react'
import Chart from "chart.js";
import classes from "./PerformanceChart.module.css";

let myLineChart;

export default class PerformanceChart extends Component {
    chartRef = React.createRef();

    componentDidMount() {
        this.buildChart();
    }

    // componentDidUpdate() {
    //     this.buildChart();
    // }

    buildChart = () => {
        const myChartRef = this.chartRef.current.getContext("2d");
        const {height: graphHeight} = myChartRef.canvas;
        const { fund } = this.props;

        if (typeof myLineChart !== "undefined") myLineChart.destroy();

        let gradientFill = myChartRef
            .createLinearGradient(0, 0, 0, graphHeight);
        gradientFill.addColorStop(0, "rgb(255, 194, 14, 0.7)");
        gradientFill.addColorStop(1, "rgb(255, 194, 14, 0.1)");

        const data = { 
            labels: fund.unitPriceHistory.map(pricePoint => pricePoint.date),
            datasets: [
            {
                backgroundColor: gradientFill,
                lineTension: 0.5,
                borderColor: "#FFC20E",
                data: fund.unitPriceHistory.map(pricePoint => pricePoint.price),
                
            }]
        }

        myLineChart = new Chart(myChartRef, {
            type: "line",
            data: data,
            options: {
                layout : {
                    padding : {
                        right: 20,
                        top: 15,
                        left: 10,
                        bottom: 10
                    }
                },
                legend : {
                    display: false,
                },
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    xAxes: [{
                        ticks: { display: false },
                        gridLines: {
                            display: false,
                            drawBorder: false
                        }
                    }],
                    yAxes: [{
                        ticks: { display: true },
                        gridLines: {
                            display: true,
                            drawBorder: false
                        }
                    }]
                },
                tooltips: {
                    displayColors: false,
                    titleFontSize: 16,
                    bodyFontSize: 14,
                    xPadding: 10,
                    yPadding: 10,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            return `$ ${tooltipItem.value}`
                        }
                    }
                }
            }
        });

    }

    render() {

        return (
            <div className={classes.graphContainer}>
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
        )
    }
}