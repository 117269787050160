import React, { Component } from 'react'
import './PageComponent.css'

export const PageComponent = props => {
    return (
        <div
            className={"page-component " + props.colour}
            style={{
            minHeight: props.height,
            textAlign: props.align,
            }}>
            {props.children}
        </div>
    )
}
