import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import 'firebase/firestore';
import { createStore, applyMiddleware, compose } from 'redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance, getFirestore, reduxFirestore } from 'redux-firestore';
import firebase from './config/fbConfig';
import fbConfig from './config/fbConfig';
import rootReducer from './store/reducers/rootReducer'
import * as serviceWorker from './serviceWorker';
import Routes from './Routes';
import thunk from 'redux-thunk';

const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true
}

firebase.firestore()

const store = createStore(rootReducer, compose(
    applyMiddleware(thunk.withExtraArgument({ getFirestore })),
    reduxFirestore(fbConfig)
))

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
}

function App() {
    return (
        <Provider store={ store }>
            <ReactReduxFirebaseProvider {...rrfProps}>
                    <Routes />
            </ReactReduxFirebaseProvider>
        </Provider>
    );
}
        
render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
