import React, { Component } from 'react';
import Papa from 'papaparse';
import './LineGraph.css';

class LineGraph extends Component {
    constructor() {
        super();
        this.state = {
            isLoaded: false,
            data: [],
            closePoints: [],
            ticks: [],
            annotationTypes: ["Management Update"],
            annotationColours: ["#0075C9"],
            hiddenAnnotations: [],

            annotations: [],

            width: null,

            topAxisLine: "",
            topAxisBarWidth: 0,
            topAxisBarPosition: null,
            mouseDownBar: false,

            timePeriod: "1Y",
            fundType: "Retail",
            graphLine: "",
            lineWidth: null,
            translateGraph: 0,
            mouseDownGraph: false,
            mouseDownGraphStart: null,
            mouseDownGraphTranslateStart: null,

            lineXPositions: [],
            lineYPositions: [],

            hoverXPosition: null,
            hoverYPosition: null,

            closestPrice: null,
            closestDate: null,

            mouse: {
                bar: 0,
                graph: 0,
            }

        }
        
        this.selectRef = React.createRef();
    }
    
    updateDimensions = () => {
        const prevBarPosition = this.state.topAxisBarPosition
        const prevBarWidth = this.state.topAxisBarWidth
        const prevWidth = this.state.width
        
        this.setState({ width: this.selectRef.current.clientWidth }, () => {
            if (this.state.isLoaded) {
                this.updateChart()
                if (prevWidth) this.updateTopAxisBar(prevBarPosition, prevBarWidth, prevWidth)
            }
        })
        return this.selectRef.current.clientWidth
    }

    updateTopAxisBar(prevBarPosition, prevBarWidth, prevWidth) {
        this.setState({ 
            topAxisBarPosition: (prevBarPosition / prevWidth) * this.state.width,
            topAxisBarWidth: (prevBarWidth / prevWidth) * this.state.width,
        })
    }

    changeTimePeriod(newTimePeriod) {
        const interval = this.state.width / this.state.data.length
        let topAxisBarWidth
        switch(newTimePeriod) {
            case "1Y":
                topAxisBarWidth = Math.min(this.state.width, interval*365)
                break
            case "Max":
                topAxisBarWidth = this.state.width
                break
            default:
                topAxisBarWidth = Math.min(this.state.width, interval*365)
                break
        }
        
        this.setState({ timePeriod: newTimePeriod, topAxisBarWidth: topAxisBarWidth, topAxisBarPosition: this.state.width - topAxisBarWidth }, this.updateChart);
    }

    toggleFundType(fundType) {
        this.props.setupChart(fundType)
        this.setState({ fundType: fundType}, this.updateChart);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.setupChart()
    }
 
    componentDidUpdate(prevProps, newProps) {
        if (this.props.data !== this.state.data) {
            this.setupChart()
        }
        if (prevProps.annotations !== this.props.annotations) this.setupAnnotations()
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    setupAnnotations() {
        if (!this.state.isLoaded || !this.props.annotations) return
        const annotations = [...this.props.annotations]
        const minMax = this.findMinMax(this.state.closePoints)
        const max = minMax.max
        const min = minMax.min
        const interval = ((this.state.width / this.state.topAxisBarWidth) * this.state.width) / this.state.data.length
        if (interval !== Infinity) {
            const dates = this.state.data.map((element, index) => {
                return element.Date
            })
            const annotationsNew = annotations.map(annotation => {
                try {
                    const index = dates.indexOf(annotation.date)
                    const annotationCopy = {
                        ...annotation,
                        index: index,
                        position: String(index * interval),
                    }
                    const unitPrice = this.state.data[annotationCopy.index]['UnitPrice']
                    if (unitPrice === "-") {
                        annotationCopy.y = 182
                    } else {
                        annotationCopy.y = this.normalise(this.state.data[annotationCopy.index]['UnitPrice'], min, max, 182) - 2
                    }
                    return annotationCopy
                } catch(e) {
                    for (let i = 0; i < 10; i++) {
                        const currentDate = Date.parse(annotation.date);
                        const date = new Date(currentDate);

                        date.setDate(date.getDate() + 3 + i);

                        const dateString = date.toISOString().split('T')[0];
                        // console.log(dateString);
                        const index = dates.indexOf(dateString);
                        if (index !== -1) {
                            const annotationCopy = {
                                ...annotation,
                                index: index,
                                position: String(index * interval),
                            }
                            annotationCopy.date = dateString;
                            const unitPrice = this.state.data[annotationCopy.index]['UnitPrice']
                            if (unitPrice === "-") {
                                annotationCopy.y = 182
                            } else {
                                annotationCopy.y = this.normalise(this.state.data[annotationCopy.index]['UnitPrice'], min, max, 182) - 2
                            }
                            return annotationCopy
                        }
                    }
                    
                }
            })
            this.setState({ annotations: annotationsNew })
        }
    }

    async setupChart() {
        await this.updateData(this.props.data)
        await this.updateDimensions()
        await this.changeTimePeriod(this.state.timePeriod)
        this.setupAnnotations()
    }

    // toJSON(filePath) {
    //     const file = require('./BakerSteelGold.csv')
    //     return new Promise((resolve, reject) => {
    //         Papa.parse(file, {
    //             header: true,
    //             download: true,
    //             complete (results) {
    //                 resolve(results.data)
    //             },
    //             error (err, file) { reject(err) }
    //         })
    //     })
    // }
    
    updateData = data => {
        const closePoints = data.map(point => {
            if (point['UnitPrice'] == NaN) {
                return "-"
            }
            return Number(point['UnitPrice'])
        })
        this.setState({ data: data, isLoaded: true, closePoints: closePoints })
        return data
    }

    updateChart = () => {
        this.updateTopAxis()
        this.updateGraph()
    }

    normalise(value, min, max, scale) {
        return scale - (scale * ((value - min) / (max - min)))
    }

    findMinMax(points) {
        let max = -Infinity
        let min = Infinity
        for (const point of points) {
            if (point === NaN) continue
            if (point > max) max = point
            if (point < min) min = point
        }
        return {
            max: max,
            min: min
        }
    }

    updateTopAxis = () => {
        const data = this.state.data
        const minMax = this.findMinMax(this.state.closePoints)
        const max = minMax.max
        const min = minMax.min
        const interval = this.state.width / data.length
        let close = data.map((point, index) => {
            const x = index * interval
            if (point['UnitPrice'] === "-") return ''
            
            const y = this.normalise(point['UnitPrice'], min, max, 32)
            if (index === 0) return `M${x} ${y}`
            return `L${x} ${y}`
        })
        close = close.filter(price => price !== '')
        this.setState({ topAxisLine: close.join(", ") })
    }

    updateGraph = () => {
        
        const data = this.state.data
        const minMax = this.findMinMax(this.state.closePoints)
        const max = minMax.max
        const min = minMax.min
        const graphWidth = (this.state.width / this.state.topAxisBarWidth) * this.state.width
        const interval = graphWidth / data.length
        let xPositions = []
        let yPositions = []

        let firstY
        if (interval !== Infinity) {
            let ticks = []
            let lineWidth = 0
            let months = [];
            let close = data.map((point, index) => {
                const x = index * interval
                xPositions.push(x.toString())
                if (this.state.timePeriod === "1Y") {
                    let monthYear = point.Date.split("-")[0] + point.Date.split("-")[1];
                    if (!( months.includes(monthYear))) {
                        months.push(monthYear);
                        ticks.push([x, point.Date]);
                    }
                } else {
                    if (point.Date.slice(-5) === "01-01") ticks.push([x, point.Date])
                }
                let y
                if (point['UnitPrice'] === "-") {
                    yPositions.push("1")
                } else {
                    y = this.normalise(point['UnitPrice'], min, max, 182)
                    yPositions.push(y.toString())
                }
                lineWidth = x
                if (index === 0) firstY = y
                if (y === 0) return `L${x} ${y}`
                if (!y) {
                    return ''
                }
                return `L${x} ${y}`
            })

            close.shift()
            close = close.filter(price => price !== '')
            let newGraphLine = [...close]
            newGraphLine.unshift(`M0 ${firstY}`)
            
            newGraphLine = newGraphLine.join(", ")
            close.unshift(`L0 ${firstY}`)
            const minY = this.normalise(min, min, max, 182)
            close.unshift(`M0 ${minY+2}`)
            close.push(`L${graphWidth-interval} ${minY+2}`)
            close = close.join(", ")

            this.setState({
                graphLine: newGraphLine,
                graphLineFill: close,
                translateGraph: -(lineWidth - this.state.width),
                lineWidth: lineWidth,
                ticks: ticks,
                graphMax: max,
                graphMin: min,
                lineXPositions: xPositions,
                lineYPositions: yPositions
            })
        }
    }

    updateBarPosition() {
        if (this.state.mouse.bar) {
            const min = Math.round(this.state.mouse.bar - (this.state.topAxisBarWidth / 2)-1)
            const max = Math.round(this.state.width - this.state.topAxisBarWidth)
            const current = Math.min(Math.max(min, 0), max)
            const graphInterval = -((this.state.lineWidth / this.state.width) * current)
            this.setState({topAxisBarPosition: current, translateGraph: graphInterval, mouseDownGraphTranslateStart: graphInterval})
        }
    }
    
    mouseDragBar(e) {
        e.preventDefault()
        if (this.state.mouseDownBar) {
            this.setState({ mouse: { bar: e.nativeEvent.offsetX } }, () => this.updateBarPosition())
        } else {
            this.setState({ mouse: { bar: e.nativeEvent.offsetX } })
        }
    }

    setDragGraph(e) {
        this.setState({
            mouseDownGraph: true,
            mouseDownGraphStart: e.nativeEvent.offsetX,
            mouseDownGraphTranslateStart: this.state.translateGraph
        })
    }
    mouseDragGraph(e) {
        e.preventDefault()
        if (this.state.data[0]) {
            const mousePosition = -this.state.translateGraph + e.nativeEvent.offsetX
            let minDistance = Infinity
            let closestX;
            let index = 0
            this.state.lineXPositions.forEach((num, i) => {
                const dist = Math.abs(mousePosition - num)
                if (dist < minDistance && this.state.lineYPositions[i] !== "1") {
                    index = i
                    minDistance = dist
                    closestX = Number(num) + this.state.translateGraph 
                }
            })

            const unitPrice = this.state.data[index]['UnitPrice']
            let closestPrice = "-"
            let yPosition = 0
            if (unitPrice !== "-") {
                closestPrice = Number(unitPrice).toFixed(2)
                yPosition = Number(this.state.lineYPositions[index]) - 2
            }

            const closestDate = this.getDate(this.state.data[index]['Date'], true)
            
            
            if (this.state.mouseDownGraph) {
                this.setState({ mouse: { graph: e.nativeEvent.offsetX }, hoverYPosition: yPosition, hoverXPosition: closestX, closestPrice: closestPrice, closestDate: closestDate }, () => this.updateGraphPosition())
            } else {
                this.setState({ mouse: { graph: e.nativeEvent.offsetX }, hoverYPosition: yPosition, hoverXPosition: closestX, closestPrice: closestPrice, closestDate: closestDate})
            }
        }
    }

    updateGraphPosition() {
        if (this.state.mouse.graph) {
            
            const drag = this.state.mouseDownGraphTranslateStart + (this.state.mouse.graph - this.state.mouseDownGraphStart)
            const newPosition = Math.min(Math.max(-(this.state.lineWidth - this.state.width), drag), 0)
            const barPosition = -((this.state.width / this.state.lineWidth) * newPosition)
            
            this.setState({
                translateGraph: newPosition,
                topAxisBarPosition: barPosition
            })
        }
    }

    toggleAnnotation(index) {
        let hiddenAnnotations = this.state.hiddenAnnotations
        if (hiddenAnnotations.includes(index)) {
            hiddenAnnotations = hiddenAnnotations.filter(value => value !== index)
        } else {
            hiddenAnnotations.push(index)
        }
        this.setState({ hiddenAnnotations: hiddenAnnotations })
    }

    getDate(dateString, year) {
        const dateComponents = dateString.split('-')
        let mon = ""
        switch(dateComponents[1]) {
            case "01":
                mon = "Jan"
                break
            case "02":
                mon = "Feb"
                break
            case "03":
                mon = "Mar"
                break
            case "04":
                mon = "Apr"
                break
            case "05":
                mon = "May"
                break
            case "06":
                mon = "Jun"
                break
            case "07":
                mon = "Jul"
                break
            case "08":
                mon = "Aug"
                break
            case "09":
                mon = "Sep"
                break
            case "10":
                mon = "Oct"
                break
            case "11":
                mon = "Nov"
                break
            default:
                mon = "Dec"
                break
        }
        if (year) return `${dateComponents[2]} ${mon} ${dateComponents[0]}`
        return `${mon} ${dateComponents[0].slice(-2)}`
    }
    
    getYear(dateString) {
        const dateComponents = dateString.split('-')
        return dateComponents[0]
    }

    render() {
        return (
            <section
                className="line-graph-wrapper"
                ref={this.selectRef}
            >
                <div className="option-toggles">
                    {this.props.fundType === "both" ?
                    <div className="fund-type">
                        <div>
                            <button
                                onClick={() => this.toggleFundType("Retail")}
                                className={this.state.fundType === "Retail" ? "selected" : ""}
                            >Retail</button>
                        </div>
                        {/*<div>
                            <button
                                onClick={() => this.toggleFundType("Institutional")}
                                className={this.state.fundType === "Institutional" ? "selected" : ""}
                            >Institutional</button>
        </div>*/}
                    </div>
                    : null
                    }
                    
                    <div className="time-periods">
                        <div>
                            <button
                                onClick={() => this.changeTimePeriod("1Y")}
                                className={this.state.timePeriod === "1Y" ? "selected" : ""}
                            >1Y</button>
                        </div>
                        <div>
                            <button
                                onClick={() => this.changeTimePeriod("Max")}
                                className={this.state.timePeriod === "Max" ? "selected" : ""}
                            >Max</button>
                        </div>
                    </div>
                </div>
                    <div height={350} className="body">
                        <div style={{position: 'relative'}}>
                            <svg
                                draggable
                                className="top-axis"
                                width={this.state.width}
                                height={32}
                                xmlns="http://www.w3.org/2000/svg"
                                onMouseDown={() => this.setState({mouseDownBar: true})}
                                onMouseUp={() => this.setState({mouseDownBar: false})}
                                onMouseMove={this.mouseDragBar.bind(this)}
                                onMouseLeave={() => this.setState({mouseDownBar: false})}
                                onClick={this.updateBarPosition.bind(this)}
                            >
                                <rect
                                    x={0}
                                    y={0}
                                    fill="#F5F3F0"
                                    fillOpacity={0.5}
                                    width={this.state.width}
                                    height={32}
                                />
                                <path
                                    className="vx-linepath"
                                    d={this.state.topAxisLine} fill="transparent" stroke="#0075C9" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" />
                                <g className="vx-group vx-axis vx-axis-top" transform="translate(0, 16)">
                                    {/* <g transform="translate(28.623962040332145, 0)">
                                        <text y={4} fontSize={12} textAnchor="middle" fill="white">July</text>
                                    </g>
                                    <g transform="translate(277.44958481613287, 0)">
                                        <text y={4} fontSize={12} textAnchor="middle" fill="white">October</text>
                                    </g>
                                    <g transform="translate(526.1625148279953, 0)">
                                        <text y={4} fontSize={12} textAnchor="middle" fill="white">2020</text>
                                    </g> */}
                                </g>
                                <rect
                                    x={this.state.topAxisBarPosition}
                                    y={0}
                                    fill="rgba(13, 110, 241, 0.2)"
                                    stroke="#0075C9"
                                    width={this.state.topAxisBarWidth}
                                    height={32}
                                    strokeWidth={0.25}
                                />
                                <rect x={0} y={0} fill="transparent" width={this.state.width} height={32} className="" />
                            </svg>
                            <div style={{position: "relative", overflow: "hidden"}}>
                                <svg
                                    className="graph-area"
                                    width={this.state.width}
                                    height={350}
                                    xmlns="http://www.w3.org/2000/svg"
                                    onMouseDown={this.setDragGraph.bind(this)}
                                    onMouseUp={() => this.setState({mouseDownGraph: false})}
                                    onMouseMove={this.mouseDragGraph.bind(this)}
                                    onMouseLeave={() => this.setState({mouseDownGraph: false})}
                                >
                                    <g transform={`translate(${this.state.translateGraph}, 0)`}>
                                        
                                        <g pointerEvents="none">
                                            <g className="vx-group vx-axis vx-axis-bottom" transform="translate(0, 256)">
                                                <g>
                                                    <rect width={this.state.lineWidth} height={1} y={0} fill="#e9ebee" />
                                                    {this.state.ticks.length === 0 ? null :
                                                        this.state.ticks.map((tick, index) => {
                                                            if (this.state.width < 550 && index % 2 === 0) return
                                                            if (!isNaN(tick[0]) && tick[0] !== Infinity) {
                                                                return (
                                                                    <g key={index} transform={`translate(${tick[0]}, 0)`}>
                                                                        <rect fill="#e9ebee" width={1} height={6} />
                                                                        {this.state.timePeriod === "1Y" ?
                                                                        <text y={20} fontSize={12} textAnchor="middle" fill="#4b4f56">{this.getDate(tick[1], false)}</text>
                                                                        :
                                                                        <text y={20} fontSize={12} textAnchor="middle" fill="#4b4f56">{this.getYear(tick[1])}</text>
                                                                        }
                                                                    </g>
                                                                )
                                                            } else return null
                                                        })
                                                    }
                                                </g>
                                            </g>
                                        </g>
                                        <g transform="translate(0, 74)">
                                            { this.state.annotations && this.state.annotations.map((marker, index) => {
                                                if (marker && !isNaN(marker.position) && !this.state.hiddenAnnotations.includes(this.state.annotationTypes.indexOf(marker.type))) {
                                                    if (marker.fundType !== this.state.fundType) return
                                                    return (
                                                        <g key={index} transform={`translate(${marker.position}, 0)`}>
                                                            <line x1={0} y1={marker.y} x2={0} y2={-10} strokeWidth={1} stroke="#0075C9" strokeOpacity="0.25" />
                                                            {/* <g transform="translate(0, 244)" visibility="visible">
                                                                <rect x={-marker.title.length*6.5} y={-4} width={marker.title.length*6.5} height={20} fill="#F5F6F7" rx={2} ry={2} />
                                                                <rect x={-1} y={-30} width={1} height={30} fill="#F5F6F7" />
                                                                <rect x={0} y={-4} fill="#0075C9" width={2} height={20} />
                                                                <path d="M0 0 L0 -8 L-4 -4 z" fill="#F5F6F7" />
                                                                <text x={-4} y={10} fill="#4b4f56" fontSize={12} textAnchor="end">{marker.title}</text>
                                                                <rect
                                                                    x={-marker.title.length*6.5}
                                                                    y={-4}
                                                                    width={marker.title.length*6.5}
                                                                    height={20}
                                                                    fill="transparent"
                                                                    cursor="pointer"
                                                                    onClick={() => this.props.toggleAnnotationFocus(marker)}
                                                                    />
                                                            </g> */}
                                                            <g cursor="pointer" transform="translate(0,-10)" onClick={() => this.props.toggleAnnotationFocus(marker)}>
                                                                <g transform="translate(0,0)">
                                                                    <circle cx={0} cy={0} fill="#FFF" strokeWidth="2" r={7} stroke="#0075C9" />
                                                                    <text x={1.5} y={4} fill="#0075C9" fontSize={12} fontWeight="900" textAnchor="end">i</text>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    )
                                                } else return null
                                                
                                            })}
                                            
                                        </g>
                                        <g transform="translate(0, 74)">
                                            <path className="vx-linepath" fill="transparent" stroke="#5F6875" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" strokeDasharray="2, 3" />
                                            <path className="graph-line" transform="translate(0, -2)" d={this.state.graphLine} fill="transparent" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            <path className="graph-line-fill" transform="translate(0, -2)" d={this.state.graphLineFill} stroke="none" />
                                            {/* White dot and crosshairs */}
                                            <g pointerEvents="none" transform={`translate(${-this.state.translateGraph}, 0)`}>
                                                <line x1={this.state.hoverXPosition} y1={-20} x2={this.state.hoverXPosition} y2={182} strokeWidth={1} stroke="#5C6874" strokeDasharray="5, 5" />
                                                <line x1="-219.98770296245584" y1={this.state.hoverYPosition} x2={this.state.width} y2={this.state.hoverYPosition} strokeWidth={1} stroke="#5C6874" strokeDasharray="5, 5" />
                                                <circle cx={this.state.hoverXPosition} cy={this.state.hoverYPosition} r={4} fill="#0D6EF1" />
                                            </g>
                                            { !isNaN(-this.state.translateGraph + this.state.hoverXPosition) ?
                                                <g transform={`translate(${-this.state.translateGraph + this.state.hoverXPosition}, -28)`}>
                                                    <path d="M4 0 L0 0 L0 4 z" fill="#FFF" />
                                                </g>
                                                : null
                                            }
                                        </g>
                                        
                                    </g>
                                    
                                    {this.state.graphMax ?
                                        <g transform="translate(10, 74)">
                                            <text fontSize={12} textAnchor="start" alignmentBaseline="hanging" fill="#4b4f56">Max: {this.state.graphMax.toFixed(2)}</text>
                                        </g>
                                        : null
                                    }
                                    {this.state.graphMin ?
                                        <g transform="translate(10, 248)">
                                            <text fontSize={12} textAnchor="start" alignmentBaseline="baseline" fill="#4b4f56">Min: {this.state.graphMin.toFixed(2)}</text>
                                        </g>
                                        : null
                                    }
                                    <g transform="translate(0, 74)">
                                        <rect fill="transparent" width={this.state.width} height={182} className="styled__PriceChartDragArea-sc-9zna36-3 cuMntx" />
                                    </g>
                                </svg>
                                {this.state.closestPrice ?
                                    <div className="header-wrapper" width="100%">
                                        <div x={this.state.hoverXPosition} className="current-x" style={{ transform: `translate(${this.state.hoverXPosition}px, -15px)`}}>
                                            <div className={(this.state.hoverXPosition > (this.state.width / 2)) ? "close switched" : "close"}>
                                                <p>{this.state.closestDate}</p>
                                                <p><strong>AUD ${this.state.closestPrice}</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                    {/* <div className="annotations">

                        { this.state.annotationTypes && this.state.annotationTypes.map((annotation, index) => {
                            return (
                                <button
                                    key={annotation}
                                    className={this.state.hiddenAnnotations.includes(index) ? "hidden" : ""}
                                    onClick={() => this.toggleAnnotation(index)}
                                >
                                    <svg width={8} height={8} viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx={4} cy={4} r={3} stroke={this.state.annotationColours[index]} strokeWidth={2} fill={this.state.annotationColours[index]} />
                                    </svg>
                                    <span>{annotation}</span>
                                </button>
                            )
                        })}
                    </div> */}
                </section>
                
        )
    }
}

export default LineGraph