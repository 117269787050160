import React, { Component } from 'react'
import garth from './Garth.png';
import justus from './Justus.png';
import skyline from './Sydney.png';
import './Team.css'

class SydneyPortfolioServices extends Component {
    render() {
        return (
            <section className="sydney portfolio-services">
                <p>OUR TEAM</p>
                <h1>Sydney office</h1>
                <div>
                    <img src={justus} alt=""/>
                    <h3>Justus Stals</h3>
                    <a href="https://www.linkedin.com/in/justus-stals-bb52a131" target="_blank" rel="noopener noreferrer">
                        <svg width="68px" height="68px" viewBox="0 0 68 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <rect id="Rectangle" fill="#0075C9" x={0} y={0} width="67.5" height="67.5" />
                            <path d="M53.325,37.8724661 L53.325,51.975 L45.0785617,51.975 L45.0785617,38.8169688 C45.0785617,35.5115641 43.8858033,33.2557546 40.900866,33.2557546 C38.6222449,33.2557546 37.2662359,34.7760049 36.6696778,36.2465911 C36.4521296,36.77232 36.3961324,37.5040657 36.3961324,38.2399796 L36.3961324,51.975 L28.1469211,51.975 C28.1469211,51.975 28.258021,29.6896139 28.1469211,27.3806817 L36.3953273,27.3806817 L36.3953273,30.8668279 C36.3785997,30.8929015 36.3568628,30.9210149 36.3411192,30.9462903 L36.3953273,30.9462903 L36.3953273,30.8668279 C37.4912087,29.1934174 39.4483379,26.8028944 43.8290011,26.8028944 C49.2563468,26.8028944 53.325,30.3180408 53.325,37.8724661 Z M19.5178948,15.525 C16.6958504,15.525 14.85,17.3601732 14.85,19.7734885 C14.85,22.1342131 16.6424473,24.0250809 19.4084051,24.0250809 L19.4635078,24.0250809 C22.3402077,24.0250809 24.1292558,22.1345678 24.1292558,19.7734885 C24.0750476,17.3601732 22.3402077,15.525 19.5178948,15.525 Z M15.3399308,51.975 L23.5860113,51.975 L23.5860113,27.3806817 L15.3399308,27.3806817 L15.3399308,51.975 Z" id="Icon" fill="#FFFFFF" fillRule="nonzero" />
                            </g>
                        </svg>
                    </a>
                </div>
                {/* <div>
                    <img src={garth} alt=""/>
                    <h3>Garth Curry</h3>
                    <a href="https://www.linkedin.com/in/garth-curry-9bb345101" target="_blank" rel="noopener noreferrer">
                    <svg width="68px" height="68px" viewBox="0 0 68 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <rect id="Rectangle" fill="#0075C9" x={0} y={0} width="67.5" height="67.5" />
                        <path d="M53.325,37.8724661 L53.325,51.975 L45.0785617,51.975 L45.0785617,38.8169688 C45.0785617,35.5115641 43.8858033,33.2557546 40.900866,33.2557546 C38.6222449,33.2557546 37.2662359,34.7760049 36.6696778,36.2465911 C36.4521296,36.77232 36.3961324,37.5040657 36.3961324,38.2399796 L36.3961324,51.975 L28.1469211,51.975 C28.1469211,51.975 28.258021,29.6896139 28.1469211,27.3806817 L36.3953273,27.3806817 L36.3953273,30.8668279 C36.3785997,30.8929015 36.3568628,30.9210149 36.3411192,30.9462903 L36.3953273,30.9462903 L36.3953273,30.8668279 C37.4912087,29.1934174 39.4483379,26.8028944 43.8290011,26.8028944 C49.2563468,26.8028944 53.325,30.3180408 53.325,37.8724661 Z M19.5178948,15.525 C16.6958504,15.525 14.85,17.3601732 14.85,19.7734885 C14.85,22.1342131 16.6424473,24.0250809 19.4084051,24.0250809 L19.4635078,24.0250809 C22.3402077,24.0250809 24.1292558,22.1345678 24.1292558,19.7734885 C24.0750476,17.3601732 22.3402077,15.525 19.5178948,15.525 Z M15.3399308,51.975 L23.5860113,51.975 L23.5860113,27.3806817 L15.3399308,27.3806817 L15.3399308,51.975 Z" id="Icon" fill="#FFFFFF" fillRule="nonzero" />
                        </g>
                    </svg>
                    </a>
                </div> */}
                <div className="spacer" />
                <img className="sydney-skyline" src={skyline} alt=""/>
            </section>
        )
    }
}

export default SydneyPortfolioServices
