import firebase from '../../config/fbConfig';

export const contact = (newContact) => {
    
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        
        firestore.collection('messages').add({ 
            email: newContact.email,
            phone: newContact.phone,
            name: newContact.name,
            type: newContact.topic,
            sentAt: new Date()
        }).then(() => {
            dispatch({ type: 'CONTACT_SUCCESS', newContact });
        }).catch((err) => {
            console.log('CONTACT_ERROR', err);
            dispatch({ type: 'CONTACT_ERROR', err})
        })
    }
}