import React, { Component } from 'react'
import ContactSection from '../Contact/ContactSection'
import CollapsibleList from '../CollapsibleList/CollapsibleList'
import MelbournePortfolioServices from '../Team/MelbournePortfolioServices'
import SydneyPortfolioServices from '../Team/SydneyPortfolioServices'
import tree from './Tree.png'
import { Footer } from '../Footer/Footer'
import './PortfolioServices.css'
import { CenteredList } from '../CenteredList/CenteredList'

class PortfolioServices extends Component {
    render() {
        const listData = [
            {
                title: "Listen and understand",
                body: '<p>General investment advice on:</p> \
                        <p>Discretionary asset management through our Managed Discretionary Accounts, wide range of international funds, or individually tailored solutions. Combinations of the above for wholesale clients.</p><br />'
            },
            {
                title: "Focus on desired outcomes",
                body: '<p>We have more than just a deep understanding of equities, bonds, property, managed funds, alternative assets and superannuation.</p> \
                        <p>What really sets us apart is the way we consider all options and apply these to every stage of wealth creation.</p><br />'
            },
            {
                title: "Build long term relationships",
                body: '<p>Expertise and understanding are vital.</p> \
                        <p>But it\'s our ability to establish a long term partnership with you that really makes the difference.</p><br />'
            },
        ]

        const centeredListData = [
            {
                title: "Discretionary portfolio management",
                body: 'By delegating the management of part or all of your day-to-day portfolio investment decisions  to a dedicated porftolio manager, we can provide you with access to a top-tier product shelf across asset classes, markets and currencies.'
            },
            {
                title: "Equities",
                body: 'We offer access to domestic equities listed on the ASX and international equities listed on most international exchanges. We offer access to a wide variety of of Australian-registered funds in our product list - not just our own.'
            },
            {
                title: "Real estate",
                body: 'We can provide access to a range of portfolio management options in this sector. Our offering includes access to the listed real estate market both locally and internationally, as well as opportunities in the unlisted space.'
            },
        ]

        return (
            <div className="component-container">
                <div className="portfolio-services">
                    <section className="header">
                        <div className="content">
                            <img src={tree} alt="Sanlam portfolio services" />
                            <div className="text">
                                <h5>PORTFOLIO SERVICES</h5>
                                <h1>A bright future<br /><span>based on sound advice</span></h1>
                                <p>
                                    Everyone has goals. Responsibilities. Ambitions. And the sheer joy of planning for a more fulfilled life. A life you want, not the one that just happens. We all hope our finances will support us throughout our lives. It's our job to work with you to make sure that they do.
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="list-component services">
                        <div className="content">
                            <div className="divider">
                                <div className="services-summary">
                                    <h5>OUR PROCESS</h5>
                                    <h1>Three steps to <span>portfolio outcomes</span></h1>
                                    <p>
                                        We have all the skills you'd expect from your investment advisor - delivering advice on portfolio management, self-managed superannuation, as well as managed funds and stockbroking expertise.
                                        <br />
                                        It's how we put it all together that creates something unique. For us, there are three essential components…
                                    </p>
                                </div>
                                <div className="services-illustration">

                                </div>
                            </div>
                            <div className="divider">
                                <CollapsibleList data={listData} />
                            </div>
                        </div>
                    </section>
                    <CenteredList data={centeredListData} title={"<h1>We accomodate<br /><span>any strategy</span></h1>"} />
                    <SydneyPortfolioServices />
                    <MelbournePortfolioServices />
                    <ContactSection activeTopic="portfolio services" />
                    <Footer />
                </div>
            </div>
        )
    }
}

export default PortfolioServices
